var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        {
          staticClass: "wholesale-cancellation",
          class: {
            "large-screen": _vm.view === "view-all",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "top-level-button",
              attrs: { "aria-label": "To Services Page Button" },
              on: { click: _vm.goToServicesPage },
            },
            [
              _c("fa-icon", {
                staticClass: "fa-sm",
                attrs: { icon: "chevron-left" },
              }),
              _vm._v("\n    Go to Services Page\n  "),
            ],
            1
          ),
          _c("span", { staticClass: "cancellation-title" }, [
            _vm._v("Compliance Cancellation"),
          ]),
          _vm.view === "splash"
            ? _c("div", { staticClass: "cancel-splash" }, [
                _c("div", { staticClass: "cancel-message" }, [
                  _c("p", [
                    _vm._v(
                      "\n        You are about to cancel Compliance Service and State filing for " +
                        _vm._s(_vm.selectedServicesCount) +
                        " Jurisdictions\n      "
                    ),
                  ]),
                  _vm._m(0),
                  _c("h3", [_vm._v("How do you want to proceed?")]),
                  _c(
                    "div",
                    { staticClass: "action-buttons" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-left" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "grey-button-outlined",
                                  attrs: {
                                    variant: "outline-secondary",
                                    "aria-label": "View All Services Button",
                                  },
                                  on: { click: _vm.viewAll },
                                },
                                [
                                  _vm._v(
                                    "\n              View All Compliance Services\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "custom-cancel-button",
                                  attrs: {
                                    "aria-label": "Cancel All Services Button",
                                    disabled: _vm.selectedServicesCount === 0,
                                  },
                                  on: { click: _vm.cancelAll },
                                },
                                [
                                  _vm._v(
                                    "\n              Cancel All Compliance Services\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm.view === "view-all"
            ? _c("div", { staticClass: "cancel-view-all" }, [
                _vm.selectedServicesCount > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "top-level-button",
                        attrs: { "aria-label": "Expand Collapse All Button" },
                        on: { click: _vm.changeCollapse },
                      },
                      [
                        _vm._v(
                          "\n      " +
                            _vm._s(
                              _vm.collapsed ? "Expand All" : "Collapse All"
                            ) +
                            "\n    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "company-details" }, [
                  _c(
                    "div",
                    { staticClass: "services-container" },
                    [
                      _vm.selectedServicesCount === 0
                        ? _c("div", [
                            _vm._v(
                              "\n          No Compliance Services to Cancel\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        _vm.companiesWithCancellableCompliance,
                        function (company, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "service-card" },
                            [
                              _c("div", { staticClass: "card-header" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "column-1 icon-clickable",
                                    attrs: {
                                      "aria-label":
                                        "Expand Collapse One Button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.collapseCompany(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "fa-lg mr-2",
                                      attrs: {
                                        icon: _vm.expandedCompanies.includes(
                                          index
                                        )
                                          ? "chevron-down"
                                          : "chevron-up",
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(company.name) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "column-2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.companyRefundTotal(index)
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                                _c("div", { staticClass: "column-3" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(company.services.length) +
                                      "\n            "
                                  ),
                                ]),
                              ]),
                              _vm._l(
                                company.services,
                                function (service, service_index) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.expandedCompany(index),
                                          expression: "expandedCompany(index)",
                                        },
                                      ],
                                      key: service_index,
                                      staticClass: "service-row",
                                    },
                                    [
                                      _c("div", { staticClass: "column-1" }, [
                                        _c(
                                          "p",
                                          { staticClass: "jurisdiction" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                service?.jurisdiction?.formatted
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "column-2" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.activeFilingRefund(service)
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "column-3" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "remove-button",
                                            attrs: {
                                              "aria-label":
                                                "Save Service Button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveService(service)
                                              },
                                            },
                                          },
                                          [
                                            _c("fa-icon", {
                                              staticClass: "fa-lg mr-2",
                                              attrs: { icon: "floppy-disk" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "refund-container" }, [
                    _vm.selectedServicesCount > 0
                      ? _c("div", { staticClass: "refund-card" }, [
                          _c("div", [
                            _c("div", { staticClass: "refund-header" }, [
                              _c("span", [_vm._v("Refund")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(_vm.refundTotals))
                                ),
                              ]),
                            ]),
                            _vm.refundTotals > 0
                              ? _c("div", { staticClass: "refund-method" }, [
                                  _vm._v("To original payment method(s)"),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "reason-container" },
                            [
                              _c(
                                "b-form",
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { staticClass: "label-sm" }, [
                                        _vm._v("Reason for Cancellation"),
                                      ]),
                                      _c(
                                        "b-form-select",
                                        {
                                          staticClass: "select-box",
                                          model: {
                                            value: _vm.feedBack,
                                            callback: function ($$v) {
                                              _vm.feedBack = $$v
                                            },
                                            expression: "feedBack",
                                          },
                                        },
                                        _vm._l(
                                          _vm.cancellationOptions,
                                          function (option) {
                                            return _c(
                                              "option",
                                              {
                                                key: option.cancellation_reason,
                                                domProps: { value: option.id },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      option.cancellation_reason
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm.isOtherOption
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "label-sm mt-3",
                                                },
                                                [
                                                  _vm._v(
                                                    "Please explain in 100 characters or less"
                                                  ),
                                                ]
                                              ),
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  id: "textarea",
                                                  placeholder:
                                                    "Enter something...",
                                                  rows: "3",
                                                  "max-rows": "6",
                                                  required: "",
                                                  maxlength: "100",
                                                },
                                                model: {
                                                  value: _vm.feedBackIfOther,
                                                  callback: function ($$v) {
                                                    _vm.feedBackIfOther = $$v
                                                  },
                                                  expression: "feedBackIfOther",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "refund-button",
                              attrs: {
                                "aria-label": "Submit Cancellation Button",
                                disabled: _vm.selectedServicesCount < 1,
                              },
                              on: { click: _vm.cancelServices },
                            },
                            [
                              _vm._v(
                                "\n            Submit Service Changes\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "saved-container" }, [
                      _vm.savedServices.length > 0
                        ? _c("div", { staticClass: "saved-message" }, [
                            _c("span", [_vm._v("Compliance Services Kept")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.savedServices.length)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.savedServices.length > 0
                        ? _c(
                            "div",
                            { staticClass: "saved-card" },
                            _vm._l(
                              _vm.companiesWithSavedCompliance,
                              function (company, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "company-header" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(company.name) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      company.services,
                                      function (service, service_index) {
                                        return _c(
                                          "div",
                                          {
                                            key: service_index,
                                            staticClass: "saved-row",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "jurisdiction" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      service?.jurisdiction
                                                        ?.formatted
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "remove-button",
                                                attrs: {
                                                  "aria-label":
                                                    "Remove service Button",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeService(
                                                      service
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("fa-icon", {
                                                  staticClass: "fa-lg",
                                                  attrs: {
                                                    icon: "minus-circle",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("confirm-bulk-compliance-cancellation-modal", {
            attrs: { bus: _vm.bus },
            on: {
              "refresh-cancellable-services": _vm.initializeData,
              "cancel-complete": _vm.cancelServicesComplete,
            },
          }),
        ],
        1
      )
    : _c("div", [_c("ct-centered-spinner")], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "warning-message" }, [
      _c("span", { staticClass: "warning-header" }, [
        _vm._v("Heads up! Cancelling Compliance will result in..."),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n            You filing your own reports with the state.\n          "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n            Potential fines if you miss the deadline or file incorrectly.\n          "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n            One more thing you have to complete.\n          "
          ),
        ]),
      ]),
      _c("span", [
        _vm._v("Our service is hassle free and makes your life easier."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }