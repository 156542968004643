<template>
  <div
    v-if="!loading"
     class="wholesale-cancellation"
     :class="{
      'large-screen': view === 'view-all',
    }">
    <div class="top-level-button" aria-label="To Services Page Button" @click="goToServicesPage">
      <fa-icon icon="chevron-left" class="fa-sm" />
      Go to Services Page
    </div>
    <span class="cancellation-title">Compliance Cancellation</span>
    <div v-if="view === 'splash'" class="cancel-splash">
      <div class="cancel-message">
        <p>
          You are about to cancel Compliance Service and State filing for {{ selectedServicesCount }} Jurisdictions
        </p>

        <div class="warning-message">
          <span class="warning-header">Heads up! Cancelling Compliance will result in...</span>
          <ul>
            <li>
              You filing your own reports with the state.
            </li>
            <li>
              Potential fines if you miss the deadline or file incorrectly.
            </li>
            <li>
              One more thing you have to complete.
            </li>
          </ul>
          <span>Our service is hassle free and makes your life easier.</span>
        </div>

        <h3>How do you want to proceed?</h3>
        <div class="action-buttons">
          <b-row>
            <b-col class="text-left">
              <b-button
                class="grey-button-outlined"
                variant="outline-secondary"
                aria-label="View All Services Button"
                @click="viewAll"
              >
                View All Compliance Services
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button
                class="custom-cancel-button"
                aria-label="Cancel All Services Button"
                :disabled="selectedServicesCount === 0"
                @click="cancelAll"
              >
                Cancel All Compliance Services
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else-if="view === 'view-all'" class="cancel-view-all">
      <div v-if="selectedServicesCount > 0" class="top-level-button" aria-label="Expand Collapse All Button" @click="changeCollapse">
        {{ collapsed ? 'Expand All' :  'Collapse All' }}
      </div>
      <div class="company-details">
        <div class="services-container">
          <div v-if="selectedServicesCount === 0">
            No Compliance Services to Cancel
          </div>
          <div v-for="(company, index) in companiesWithCancellableCompliance" :key="index" class="service-card">
            <div class="card-header">
              <div class="column-1 icon-clickable" aria-label="Expand Collapse One Button" @click="collapseCompany(index)">
                <fa-icon
                  :icon="expandedCompanies.includes(index) ? 'chevron-down' : 'chevron-up'"
                  class="fa-lg mr-2" />
                {{ company.name }}
              </div>
              <div class="column-2">
                {{ companyRefundTotal(index) | currency }}
              </div>
              <div class="column-3">
                {{  company.services.length }}
              </div>
            </div>
            <div v-show="expandedCompany(index)" v-for="(service, service_index) in company.services" :key="service_index" class="service-row">
              <div class="column-1">
                <p class="jurisdiction">{{ service?.jurisdiction?.formatted }}</p>
              </div>
              <div class="column-2">
                {{ activeFilingRefund(service) | currency }}
              </div>
              <div class="column-3">
                <button class="remove-button" aria-label="Save Service Button" @click="saveService(service)">
                  <fa-icon icon="floppy-disk" class="fa-lg mr-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="refund-container">
          <div v-if="selectedServicesCount > 0" class="refund-card">
            <div>
              <div class="refund-header">
                <span>Refund</span>
                <span>{{ refundTotals | currency }}</span>
              </div>
              <div v-if="refundTotals > 0" class="refund-method">To original payment method(s)</div>
            </div>

            <div class="reason-container">
              <b-form>
                <b-form-group>
                  <label class="label-sm">Reason for Cancellation</label>
                  <b-form-select v-model="feedBack" class="select-box">
                    <option v-for="option in cancellationOptions" :key="option.cancellation_reason" :value="option.id">
                      {{ option.cancellation_reason }}
                    </option>
                  </b-form-select>
                  <div v-if="isOtherOption">
                    <label class="label-sm mt-3">Please explain in 100 characters or less</label>
                    <b-form-textarea
                      id="textarea"
                      v-model="feedBackIfOther"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="6"
                      required
                      maxlength="100"
                    />
                  </div>
                </b-form-group>
              </b-form>
            </div>

            <button class="refund-button" aria-label="Submit Cancellation Button" :disabled="selectedServicesCount < 1" @click="cancelServices">
              Submit Service Changes
            </button>
          </div>

          <div class="saved-container">
            <div v-if="savedServices.length > 0" class="saved-message">
              <span>Compliance Services Kept</span>
              <span>{{ savedServices.length }}</span>
            </div>
            <div v-if="savedServices.length > 0" class="saved-card">
              <div v-for="(company, index) in companiesWithSavedCompliance" :key="index">
                <div class="company-header">
                  {{ company.name }}
                </div>
                <div
                  v-for="(service, service_index) in company.services"
                  :key="service_index"
                  class="saved-row"
                >
                  <span class="jurisdiction">
                    {{ service?.jurisdiction?.formatted }}
                  </span>
                  <button class="remove-button" aria-label="Remove service Button" @click="removeService(service)">
                    <fa-icon icon="minus-circle" class="fa-lg" />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <confirm-bulk-compliance-cancellation-modal
      :bus="bus"
      @refresh-cancellable-services="initializeData"
      @cancel-complete="cancelServicesComplete"
    />
  </div>
  <div v-else>
    <ct-centered-spinner />
  </div>
</template>
<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  SET_SELECTED_SERVICES,
  SET_SAVED_SERVICES,
  SET_REFUNDABLE_FILINGS,
} from '@/store/mutations'
import { REFUNDABLE_STATUSES } from '@/common/modules/constants'
import Vue from 'vue'

export default {
  name: 'WholesaleBulkCancellation',
  components: {
    ConfirmBulkComplianceCancellationModal: () => import('@/components/cancellation/ConfirmBulkComplianceCancellationModal.vue'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
  },
  data() {
    return {
      bus: new Vue(),
      collapsed: false,
      companiesWithCancellableCompliance: [],
      companiesWithSavedCompliance: [],
      compliancePaidInvoices: [],
      expandedCompanies: [],
      feedBack: null,
      feedBackIfOther: null,
      invoices: [],
      loading: false,
      query: {},
      refundableFilings: {},
      view: 'splash',
    }
  },
  computed: {
    ...mapGetters('services', ['companyServices', 'selectedServices', 'savedServices']),
    ...mapGetters('orderItems', ['complianceFilings']),
    ...mapGetters('cancellationReasons', ['allOptionsGeneric', 'otherOptions']),
    cancellationOptions() {
      return this.allOptionsGeneric
    },
    isOtherOption() {
      if (this.feedBack) {
        return this.otherOptions.filter(o => o.id === this.feedBack).length > 0
      } else {
        return false
      }
    },
    refundTotals() {
      return this.selectedServices.reduce((total, service) => {
        const key = `${service.object_id}_${service.company_id}`
        return total + (this.refundableFilings[key]?.amount || 0)
      }, 0)
    },
    selectedServicesCount() {
      return this.selectedServices.length
    },
  },
  async mounted() {
    this.loading = true
    await this.initializeData()
    this.loading = false
  },
  methods: {
    ...mapActions('services', ['companiesIndexSimple', 'getServices']),
    ...mapActions('cancellationReasons', ['getAllOptions']),
    ...mapActions('invoices', ['fetchInvoiceFromOrderItem']),
    ...mapActions('orderItems', ['loadComplianceActiveFilings']),
    ...mapMutations('services', { setSelected: SET_SELECTED_SERVICES, setSaved: SET_SAVED_SERVICES }),
    ...mapMutations('orderItems', { setRefundableFilings: SET_REFUNDABLE_FILINGS }),
    async cancelServices() {
      this.bus.$emit('showBulkCancel', this.feedBack, this.feedBackIfOther)
    },
    async cancelServicesComplete() {
      this.setSelected([])
      await this.$router.push({ name: 'cancellation' })
    },
    async goToServicesPage() {
      await this.$router.push({ name: 'services' })
    },
    async initializeData() {
      await this.getAllOptions()
      this.setSelected([])
      this.query = { service_type: 'compliance', cancelled: false }

      await this.loadIndexSimple()
      await this.loadActiveFilings()

      this.groupServicesByCompany()
      this.groupSavedServicesByCompany()

      this.expandedCompanies = Object.keys(this.companiesWithCancellableCompliance)
    },
    async loadActiveFilings() {
      const service_ids = this.selectedServices.map(service => service.id)
      await this.loadComplianceActiveFilings(service_ids)

      const fetchInvoicePromises = this.complianceFilings
        .filter(filing => REFUNDABLE_STATUSES.includes(filing.status))
        .map(async (filing) => {
          const invoice = await this.fetchInvoiceFromOrderItem(filing.id)
          if (invoice.status === "paid" && invoice.last4) {
            const key = `${filing.registration_id}_${filing.company.id}`
            this.refundableFilings[key] = invoice
          }
        })

      await Promise.all(fetchInvoicePromises)

      this.setRefundableFilings(this.refundableFilings)
    },
    async loadIndexSimple() {
      const params = {
        order_by: 'name',
        order_direction: 'desc',
        offset: 0,
        limit: 500,
      }

      params.filter = Object.entries(this.query).map(([key, value]) => ({
        scope: key,
        value: value,
      }))

      const data = await this.companiesIndexSimple(params)

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }
      this.companiesWithCancellableCompliance = data.result.companies
      const promises = this.companiesWithCancellableCompliance.map(company => this.selectCompanyServices(company))

      await Promise.all(promises)
    },
    async selectCompanyServices(company) {
      const result = await this.getServices(this.requestParams(company))
      if (result?.success) {
        const services = this.companyServices(company.id)
        this.selectAll(services)
        this.servicesLoaded = true
      } else {
        this.errorToast('Error loading services')
      }
    },
    activeFilingRefund(service) {
      const key = `${service.object_id}_${service.company_id}`
      return this.refundableFilings[key]?.amount || 0
    },
    cancelAll() {
      this.bus.$emit('showBulkCancel', this.feedBack, this.feedBackIfOther)
    },
    changeCollapse() {
      if (this.collapsed) {
        this.expandedCompanies = []
        for (const companyId in this.companiesWithCancellableCompliance) {
          this.expandedCompanies.push(companyId)
        }
      }
      else {
        this.expandedCompanies = []
      }
      this.collapsed = !this.collapsed
    },
    collapseCompany(changed_id) {
      if (this.expandedCompanies.includes(changed_id)) {
        this.expandedCompanies = this.expandedCompanies.filter(id => id !== changed_id)
      } else {
        this.expandedCompanies.push(changed_id)
      }
    },
    companyRefundTotal(id) {
      let refundTotal = 0

      const services = this.selectedServices.filter(service => service.company_id === id)
      for (const service of services) {
        const key = `${service.object_id}_${service.company_id}`
        refundTotal += this.refundableFilings[key]?.amount || 0
      }

      return refundTotal
    },
    expandedCompany(id) {
      return this.expandedCompanies.includes(id)
    },
    groupSavedServicesByCompany() {
      this.companiesWithSavedCompliance = this.savedServices.reduce((acc, service) => {
        const companyId = service.company.id

        if (!acc[companyId]) {
          acc[companyId] = { name: service.company.name, services: [] }
        }

        acc[companyId].services.push(service)
        return acc
      }, {})
    },
    groupServicesByCompany() {
      this.companiesWithCancellableCompliance = this.selectedServices.reduce((acc, service) => {
        const companyId = service.company.id

        if (!acc[companyId]) {
          acc[companyId] = { name: service.company.name, services: [] }
        }

        acc[companyId].services.push(service)
        return acc
      }, {})
    },
    removeService(service) {
      const services = this.savedServices.filter(s => s.id !== service.id)

      this.setSaved(services)
      this.setSelected([...this.selectedServices, service])
      this.groupServicesByCompany()
      this.groupSavedServicesByCompany()
    },
    requestParams(company) {
      const params =  {
        status: ['active'],
        company_id: company.id,
        include_company: true,
        include_jurisdiction: true,
      }
      const query = {}

      params.search_name = 'search_filtered_wholesaler'
      query['service_type'] = 'compliance'
      params.query = query

      return params
    },
    saveService(service) {
      const services = this.selectedServices.filter(s => s.id !== service.id)

      this.setSaved([...this.savedServices, service])
      this.setSelected(services)
      this.groupServicesByCompany()
      this.groupSavedServicesByCompany()
    },
    selectAll(services) {
      const filtered = [...this.selectedServices.filter(ss => services.every(svc => svc.id !== ss.id))]
      this.setSelected(this.allSelected
        ? [...filtered]
        : [...filtered, ...services],
      )
    },
    viewAll() {
      this.view = 'view-all'
    },
  },
}
</script>

<style lang="scss">
.icon-clickable {
  cursor: pointer;
}

.top-level-button {
  width: auto;
  align-items: flex-start;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #2E3798;
  cursor: pointer;
  align-self: flex-start;
}

.wholesale-cancellation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 811px;
  margin: 0 auto;
  overflow-y: auto;

  &.large-screen {
    max-width: 1017px !important;
  }

  .warning-message {
    width: 100%;
    max-width: 711px;
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 400;

    .warning-header {
      font-weight: 600;
      font-size: 1.125rem;
    }
  }
}

.cancellation-title {
  width: 100%;
  text-align: left;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 2rem;
}

.cancel-splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 711px;
  margin: 0 auto;
  margin-left: 0;

  .cancel-message {
    width: 100%;
    text-align: left;
    font-weight: 400;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .action-buttons {
    margin: 2rem 0;
    width: 100%;
    max-width: 711px;
  }

  .grey-button-outlined {
    background-color: #FFFFFF !important;
    border-color: #CECED2 !important;
    border-radius: 5px;
    color: #4E4E52;
  }
  .grey-button-outlined:hover {
    background-color: #CDCDCD !important;
  }

  .custom-cancel-button {
    background-color: #FFFFFF;
    border-color: #CECED2;
    border-radius: 5px;
    color: #F93F36;
  }

  .custom-cancel-button:hover:not([disabled]) {
    background-color: #CECED2;
  }
}

.cancel-view-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-left: 0;

  .company-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 2rem;

    .services-container {
      flex: 1;
      max-width: 60%;

      .service-card {
        width: 100%;
        margin-bottom: 1rem;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;

        .card-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          font-weight: 600;
          font-size: 18px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-color: #FFFFFF;
        }

        .column-1 {
          flex: 6;
          text-align: left;
        }

        .column-2 {
          flex: 1;
          text-align: right;
        }

        .column-3 {
          flex: 1;
          text-align: right;
        }

        .service-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          width: 95%;
          padding: 1rem 0;
          margin: auto;

          .service-name {
            font-size: 14px;
            margin: 0;
            color: #4E4E52;
          }

          .jurisdiction {
            font-size: 12px;
            margin: 0;
            color: #88888C;
          }

          .remove-button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: #4E4E52;
            outline: none;
          }
        }

        .service-row:last-child {
          border-bottom: none;
        }
      }

      .service-card:last-child {
        margin-bottom: 0;
      }
    }

    .refund-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 40%;
      flex: 1;

      .refund-card {
        flex: 1;
        max-width: 100%;
        padding: 2rem;
        border-radius: 1px;
        background-color: #F7F7FB;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
      }

      .reason-container {
        padding: 0;
        margin: 1rem auto;
        font-weight: 400;

        .select-box {
          min-height: unset !important;
          height: 40px;
          border-radius: 8px;
        }
      }
    }

    .saved-container {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      margin: auto;
      overflow-y: auto;
    }

    .saved-message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-weight: 600;
      padding: .5rem;
    }

    .saved-card {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: #fff;
      padding: .5rem 1rem;
    }

    .company-header {
      font-weight: 600;
      font-size: 16px;
      padding-top: .5rem;
      border-top: 1px solid #C0C0C0;
      color: #333;
    }

    .saved-card > div:first-child .company-header {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    .saved-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 1rem;
      font-size: 14px;
    }

    .jurisdiction {
      font-size: 12px;
      color: #888;
    }

    .remove-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #4E4E52;
      outline: none;
    }

    .refund-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
    }

    .refund-method {
      font-size: 12px;
      font-weight: 400;
      color: #88888C;
      text-align: right;
      margin-top: 2px;
    }

    .refund-button {
      background-color: #F93F36;
      color: white;
      border: 1px;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      margin-top: auto;
    }

    .refund-button:hover {
      background-color: #D32F2F;
    }

    .refund-button:disabled {
      background-color: #D32F2F;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .company-details {
    flex-wrap: wrap;
    justify-content: center;
  }

  .services-container {
    max-width: 100% !important;
  }

  .refund-container {
    max-width: 100% !important;
  }
}
</style>
